<h1 class="text-center font-bold text-[2.125em] pt-10">MY NFT</h1>
<div class="mx-[10%]">
  <div class="flex flex-col mt-10 md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0 md:space-x-3">
    <input type="text" placeholder="Search something..." maxlength="4000"
      class="border outline-none border-[1px] border-[#F59ADC] bg-white shadow-md rounded-lg px-4 py-3 w-full md:w-[100%]">
    <button style="background: linear-gradient(90deg, #F59ADC, #D1A5D8);"
      class="hover:bg-blue-600 border-none shadow-md text-white font-semibold px-4 py-3 rounded-md w-full md:w-auto">Search</button>
  </div>
</div>
<div class="mx-[5%]">
  <h2 class="font-kodchasan mt-[0.83em] mb-[0.83em] weight-600 text-[30px]">Your NFT</h2>
  <p class="text-center" *ngIf="nfts.length == 0">You're currently having no NFT</p>
  <div class="item-container grid grid-cols-2 md:grid-cols-4 gap-5">
    <ng-container *ngFor="let nft of nfts">
    <app-nftcard (click)="openModal(nft.tokenId)" [transaction_type]="'Sell'" [uri]="nft.tokenURI" [price]="nft.price"
      ></app-nftcard>
    </ng-container>
  </div>
</div>

<app-sell-modal *ngIf="showModal" (closeModal)="closeModal()" [tokenID]="tokenID">
</app-sell-modal>
