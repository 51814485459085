import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.css']
})
export class FriendsComponent {
  @Input() size: number = 24;
  @Input() className: string = '';

  get svgSize(): string {
    return `${this.size}px`;
  }
}
