import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from './interfaces/user';
@Injectable({
  providedIn: 'root',
})
export class ArenaService {
  private apiUrl = 'https://api.helola.com/api/game';
  // private apiUrl = 'http://localhost:8000/api/game';
  private OPTIONS = ['energy', 'boost'];

  constructor(private http: HttpClient) {}

  createUser(user: {
    telegram_id: number;
    referrer_id: number;
  }): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/users/register/`, user);
  }

  getUserMoney(userId: number | null): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/users/${userId}/money/`);
  }

  updateUserMoney(userId: number | null, amount: number): Observable<User> {
    return this.http.patch<User>(
      `${this.apiUrl}/users/${userId}/money/`,
      amount
    );
  }

  upgradeUserLevel(userId: number | null, option: string): Observable<User> {
    if (!this.OPTIONS.includes(option)) {
      throw new Error('Invalid option');
    }
    return this.http.post<User>(`${this.apiUrl}/upgrade/${userId}/`, option);
  }
  claim_daily_reward(userId: number | null): Observable<User> {
    return this.http.post<User>(
      `${this.apiUrl}/users/${userId}/dailyreward/`,
      {}
    );
  }
  referral(userId: number | null, referrerId: string | null ): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/users/register/`, {
      telegram_id: userId,
      referrer_id: Number(referrerId),
    });
  }
}
