import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-coins',
  templateUrl: './coins.component.html',
  styleUrls: ['./coins.component.css']
})
export class CoinsComponent {
  @Input() size: number = 24;
  @Input() className: string = '';

  get svgSize(): string {
    return `${this.size}px`;
  }
}
