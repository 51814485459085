import { Component, OnInit, OnDestroy } from '@angular/core';
import { TonConnectUI, TonConnectUiCreateOptions } from '@tonconnect/ui';
import { TonConnect } from '@tonconnect/sdk';
import { TonService } from '../ton.service';
import { Subscription } from 'rxjs';
import { toNano, address, beginCell } from '@ton/ton';
import { NFTService } from '../nft.service';
import { NftCollection } from '../../contracts/NftCollection';
import { NftSingle, NftData } from '../../contracts/NftSingle';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit, OnDestroy {
  tonConnectUI: TonConnectUI;
  balance: number = 0;
  walletConnected = false;
  walletId = '';
  subject: Subscription = new Subscription();
  collectionSubject: Subscription = new Subscription();

  constructor(private tonService: TonService, private nftService: NFTService) {
    const connector: TonConnectUiCreateOptions = {
      connector: new TonConnect({
        manifestUrl: 'https://ton.vote/tonconnect-manifest.json',
      }),
    };
    this.tonConnectUI = new TonConnectUI(connector);
    this.tonConnectUI.onStatusChange((walletAndwalletInfo) => {
      if (walletAndwalletInfo) {
        this.walletId = walletAndwalletInfo.account.address;
        this.walletConnected = this.tonConnectUI.connected;
        this.tonService.changeWalletId(this.walletId);
      }
    });
  }

  ngOnInit(): void {
    this.checkWalletConnected();

    // this.tonConnectUI.openModal();
    this.subject = this.nftService.subject.subscribe((data) => {
      this.makeTransaction(data);
    });
    this.collectionSubject = this.nftService.collectionSubject.subscribe(
      (data) => {
        this.deployNFTCollection(data);
      }
    );
  }

  ngOnDestroy(): void {
    this.collectionSubject.unsubscribe();
    this.subject.unsubscribe();
  }

  async disconnectWallet() {
    await this.tonConnectUI.disconnect();
  }

  connectToWallet(): void {
    this.disconnectWallet();
    this.tonConnectUI.openModal();
  }

  async checkWalletConnected() {
    this.walletConnected = this.tonConnectUI.connected;
  }

  async deployNFTSingle(
    contentUrl: string,
    royaltyFactor: number,
    royaltyBase: number
  ) {
    const nftData: NftData = {
      ownerAddress: address(this.walletId),
      editorAddress: address(this.walletId),
      content: contentUrl,
      royaltyParams: {
        royaltyFactor: royaltyFactor,
        royaltyBase: royaltyBase,
        royaltyAddress: address(this.walletId),
      },
    };

    const nft = new NftSingle(nftData);
    const stateInit = nft.stateInit;
    if (stateInit.code && stateInit.data) {
      const stateInitBoc = beginCell()
        .storeUint(6, 5)
        .storeRef(stateInit.code)
        .storeRef(stateInit.data)
        .endCell()
        .toBoc()
        .toString('base64');
      this.tonConnectUI.sendTransaction({
        validUntil: Math.floor(Date.now() / 1000) + 300,
        messages: [
          {
            amount: toNano(0.05).toString(),
            address: nft.address.toString(),
            stateInit: stateInitBoc,
          },
        ],
      });
    } else {
      console.error('Error creating state init');
    }
  }

  async deployNFTCollection(data: {
    royaltyPercent: number;
    collectionContentUrl: string;
  }) {
    const collectionData = {
      ownerAddress: address(this.walletId),
      royaltyPercent: data.royaltyPercent ? data.royaltyPercent : 0.6,
      royaltyAddress: address(this.walletId),
      nextItemIndex: 0,
      collectionContentUrl: data.collectionContentUrl,
      commonContentUrl: `https://api.helola.com/api/echo/uri/`,
    };

    const collection = new NftCollection(collectionData);
    const stateInit = collection.stateInit;
    if (stateInit.code && stateInit.data) {
      const stateInitBoc = beginCell()
        .storeUint(6, 5)
        .storeRef(stateInit.code)
        .storeRef(stateInit.data)
        .endCell()
        .toBoc()
        .toString('base64');
      this.tonConnectUI.sendTransaction({
        validUntil: Math.floor(Date.now() / 1000) + 300,
        messages: [
          {
            amount: toNano(0.05).toString(),
            address: collection.address.toString(),
            stateInit: stateInitBoc,
          },
        ],
      });
    } else {
      console.error('Error creating state init');
    }
  }

  async makeTransaction(data: {
    singleMode: boolean;
    nftBody?: string;
    collectionAddress?: string;
    singleNftUrl: string;
  }) {
    if (data.singleMode) {
      this.deployNFTSingle(data.singleNftUrl, 1000, 1000);
    } else {
      console.log('Collection address:', data.collectionAddress);
      console.log('NFT body:', data.nftBody);
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 300,
        messages: [
          {
            address: data.collectionAddress
              ? data.collectionAddress
              : 'EQCErOjx3Tylw2X0e5mMkUOqtbWg07KnJwTgiVbH0j7_qDpU',
            amount: toNano(0.05).toString(),
            payload: data.nftBody || '',
          },
        ],
      };

      try {
        await this.tonConnectUI.sendTransaction(transaction);
      } catch (error) {
        console.error('Error making transaction:', error);
      }
    }
  }
}
