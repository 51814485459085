import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Cell } from '@ton/core';

@Injectable({
  providedIn: 'root'
})
export class TonService {
  private walletIdSource = new BehaviorSubject<string>('');
  currentWalletId = this.walletIdSource.asObservable();

  constructor() {}

  changeWalletId(walletId: string) {
    this.walletIdSource.next(walletId);
  }
}
