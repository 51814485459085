import { Component } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-image-generate',
  templateUrl: './image-generate.component.html',
  styleUrls: ['./image-generate.component.css'],
})
export class ImageGenerateComponent {
  title = 'client';
  prompt: string = 'Beautiful sunset over the city';
  imageUrls: string[] = [];
  model: string = 'dall-e-2';
  n: number = 1;
  quality: string = 'standard';
  size: string = '1024x1024';
  style: string = 'vivid';
  isLoading: boolean = false;
  showModal: boolean = false;
  chosenURL: string = '';

  constructor(private http: HttpClient) {}

  sendPrompt() {
    if (this.prompt) {
      this.imageUrls = [];
      this.isLoading = true;

      const apiUrl = 'https://api.helola.com/api/echo/';
      const params = new HttpParams()
        .set('prompt', this.prompt)
        .set('model', this.model)
        .set('n', Math.min(10, this.n).toString())
        .set('quality', this.quality)
        .set('size', this.size)
        .set('style', this.style);

      this.http
        .get<{ data: { url: string }[] }>(apiUrl, { params })
        .subscribe((response) => {
          this.isLoading = false;
          this.imageUrls = response.data.map((item) => item.url);
        });
    }
  }

  openModal(chosenURL: string) {
    this.chosenURL = chosenURL;
    this.showModal = true;
  }

  closeModal() {
    this.chosenURL = '';
    this.showModal = false;
  }

  isNDisabled(): boolean {
    const isDalle3 = this.model === 'dall-e-3';
    this.n = isDalle3 ? 1 : Math.min(10, this.n);
    return isDalle3;
  }

  isQualityDisabled(): boolean {
    const isNotDalle3 = this.model !== 'dall-e-3';
    if (isNotDalle3) this.quality = 'standard';
    return isNotDalle3;
  }

  isSizeDisabled(): boolean {
    const isNotDalle3 = this.model !== 'dall-e-3';
    if (isNotDalle3) this.size = '1024x1024';
    return isNotDalle3;
  }

  isStyleDisabled(): boolean {
    const isNotDalle3 = this.model !== 'dall-e-3';
    if (isNotDalle3) this.style = 'vivid';
    return isNotDalle3;
  }
}
