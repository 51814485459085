import { TonClient } from '@ton/ton';
import { Address } from '@ton/core';
import { environment } from '../environments/environment';
export const toncenter = new TonClient({
  endpoint: 'https://testnet.toncenter.com/api/v2/jsonRPC',
});

// export const nftCollectionAddress = Address.parse("EQCErOjx3Tylw2X0e5mMkUOqtbWg07KnJwTgiVbH0j7_qDpU");
//https://testnet.explorer.tonnft.tools/collection/EQCb5sNTFZ5lFfvmXISEOckLob_5gg0UzZKSm8AH1ZAM8WTH

export async function getNextItem(nftCollectionAddress: string | undefined) {
  let { stack } = await toncenter.runMethod(
    nftCollectionAddress
      ? Address.parse(nftCollectionAddress)
      : Address.parse('EQCErOjx3Tylw2X0e5mMkUOqtbWg07KnJwTgiVbH0j7_qDpU'),
    'get_collection_data'
  );
  let nextItemIndex = stack.readBigNumber();

  return nextItemIndex;
}
