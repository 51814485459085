import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.css']
})
export class MarketplaceComponent {
  nfts: any[] = [1,1,1,1];
  total_nft_price_higher_than_zero: number = 0;

  constructor(
    private http: HttpClient,
    private router: Router) { }

  async ngOnInit() {
  }

  navigate(uri: string) {
    this.http.get<{ id: number }>(uri).subscribe(response => {
      this.router.navigate(['/detailnft/', response.id]);
    }
    );
  }
}
