import { Component, Input  } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent {
  @Input() size: number = 24;
  @Input() className: string = '';

  get svgSize(): string {
    return `${this.size}px`;
  }
}
