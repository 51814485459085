import { Component, OnInit, OnDestroy } from '@angular/core';
import { ArenaService } from '../arena.service';
import { UserModel } from '../models/user';
import { User } from '../interfaces/user';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

declare const Telegram: any;

@Component({
  selector: 'app-arena',
  templateUrl: './arena.component.html',
  styleUrls: ['./arena.component.css'],
})
export class ArenaComponent implements OnInit, OnDestroy {
  user: UserModel | null = null;
  dailyRewardTimeLeft = 0;
  dailyCipherTimeLeft = 0;
  dailyComboTimeLeft = 0;
  isDailyRewardClaimed = false;
  isModalOpen = false;
  telegramUserID: number | null = 1;
  energy = 0;
  pointsPerTap = 1;
  energyCap = 1;
  boostValue = 1;
  private energyUpdateInterval: any;
  upgradeMode = true;
  levelNames = [
    'Bronze',
    'Silver',
    'Gold',
    'Platinum',
    'Diamond',
    'Epic',
    'Legendary',
    'Master',
    'GrandMaster',
    'Lord',
  ];
  levelMinPoints = [
    0, 5000, 25000, 100000, 1000000, 2000000, 10000000, 50000000, 100000000,
    1000000000,
  ];
  levelIndex = 0;
  points = 0;
  profitPerHour = 0;
  clicks: Array<{ id: number; x: number; y: number }> = [];
  pointsToAdd = 1;

  constructor(private gameService: ArenaService) {}

  ngOnInit() {
    Telegram.WebApp.expand();
    this.fetchTelegramUserID();
    const initData = Telegram.WebApp.initDataUnsafe;
    const startParam = initData.start_param || initData.query_id;
    const params = new URLSearchParams(startParam);
    const referrerTelegramId = params.get('referrer_id');
    this.gameService
      .referral(this.telegramUserID, referrerTelegramId)
      .subscribe({
        next: () => console.log('Referral successful'),
        error: (error) => console.error('Error processing referral:', error),
      });
    setTimeout(() => {
      this.loadUserData();
      this.initializeTimers();
    }, 2000);
  }

  ngOnDestroy() {
    clearInterval(this.energyUpdateInterval);
  }

  private initializeTimers() {
    this.energyUpdateInterval = setInterval(() => this.loadUserData(), 2000);

    setInterval(() => {
      this.updateTimers();
    }, 1000);
  }

  private updateTimers() {
    this.dailyRewardTimeLeft = this.calculateTimeLeft('reward');
    this.dailyCipherTimeLeft = Math.max(0, this.dailyCipherTimeLeft - 1);
    this.dailyComboTimeLeft = Math.max(0, this.dailyComboTimeLeft - 1);
  }

  updateUserMoney() {
    this.gameService.updateUserMoney(this.telegramUserID, 0).subscribe({
      next: (data: User) => console.log(data.energy),
      error: (error) => console.error('Error updating user money:', error),
    });
  }

  openInviteModal() {
    this.fetchTelegramUserID();
    this.upgradeMode = false;
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }

  openBoostModal() {
    this.fetchTelegramUserID();
    this.upgradeMode = true;
    this.isModalOpen = true;
  }

  private fetchTelegramUserID() {
    if (Telegram && Telegram.WebApp) {
      this.telegramUserID = Telegram.WebApp.initDataUnsafe?.user?.id || 1;
      console.log(`Telegram User ID: ${this.telegramUserID}`);
    } else {
      console.error('Telegram user ID not found.');
    }
  }

  claimDailyReward() {
    this.gameService.claim_daily_reward(this.telegramUserID).subscribe({
      next: (data: User) => {
        this.loadUserData();
        this.dailyRewardTimeLeft = this.calculateTimeLeft('reward');
      },
      error: (error) => console.error('Error claiming daily reward:', error),
    });
  }

  loadUserData() {
    this.gameService.getUserMoney(this.telegramUserID).subscribe({
      next: (data) => {
        this.points = data.money;
        this.energy = data.energy;
        this.energyCap = data.energyCap;
        this.boostValue = data.boostValue;
        this.levelIndex = this.calculateLevelIndex(this.points);
        this.pointsPerTap = data.boostValue;
        this.isDailyRewardClaimed = data.dailyRewardClaimed;
      },
      error: (error) => console.error('Error fetching user money:', error),
    });
  }

  calculateLevelIndex(points: number): number {
    return this.levelMinPoints.reduce(
      (index, minPoints, i) => (points >= minPoints ? i : index),
      0
    );
  }

  private calculateTimeLeft(type: string): number {
    switch (type) {
      case 'reward':
        // tomorrow's date countdown
        return this.isDailyRewardClaimed
          ? new Date().setHours(24, 0, 0, 0) - new Date().getTime()
          : 0;
      case 'cipher':
        return 1800;
      case 'combo':
        return 7200;
      default:
        return 0;
    }
  }

  calculateProgress(): number {
    const minPoints = this.levelMinPoints[this.levelIndex];
    const maxPoints = this.levelMinPoints[this.levelIndex + 1];
    return ((this.points - minPoints) / (maxPoints - minPoints)) * 100;
  }

  formatProfitPerHour(profitPerHour: number): string {
    return profitPerHour > 1000
      ? `${(profitPerHour / 1000).toFixed(1)}K`
      : profitPerHour.toString();
  }

  handleCardClick(event: MouseEvent): void {
    if (this.pointsPerTap <= this.energy) {
      this.energy -= this.pointsPerTap;
      this.points += this.pointsPerTap;
      this.vibrate();
      this.createPointsElement(event.clientX, event.clientY);
      this.updateUserMoney();
    } else {
      console.error('Not enough energy to perform this action.');
    }
  }

  private vibrate() {
    if ('vibrate' in navigator) {
      navigator.vibrate(100);
    }
    else{
      Telegram.WebApp.HapticFeedback.impactOccurred('medium');
    }
    // Telegram.WebApp.HapticFeedback.impactOccurred('medium');
  }

  private createPointsElement(x: number, y: number) {
    const pointsElement = document.createElement('div');
    pointsElement.className =
      'absolute min-w-[45px] text-[25px] text-white font-bold pointer-events-none';
    pointsElement.textContent = `+ ${this.pointsPerTap}`;
    pointsElement.style.left = `${x}px`;
    pointsElement.style.top = `${y}px`;
    pointsElement.style.zIndex = `111111`;
    pointsElement.style.animation = 'floatUpFade 2s forwards';

    const container = document.getElementById('points-container');
    if (container) {
      container.appendChild(pointsElement);
      setTimeout(() => pointsElement.remove(), 2000);
    }

    this.addKeyframes();
  }

  private addKeyframes() {
    let style = document.getElementById('floatUpFade-animation-style');
    if (!style) {
      style = document.createElement('style');
      style.id = 'floatUpFade-animation-style';
      style.innerHTML = `
        @keyframes floatUpFade {
          0% { opacity: 1; transform: translateY(0); }
          100% { opacity: 0; transform: translateY(-60px); }
        }
      `;
      document.head.appendChild(style);
    }
  }

  upgradeUserLevel(option: string): void {
    this.gameService.upgradeUserLevel(this.telegramUserID, option).subscribe({
      next: (data: User) => this.loadUserData(),
      error: (error) => console.error('Error upgrading user level:', error),
    });
  }

  handleAnimationEnd(id: number): void {
    this.clicks = this.clicks.filter((click) => click.id !== id);
  }
}
