import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocialService } from '../social.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css'],
})
export class TestComponent {
  private fbPageId = '100092286823167';

  constructor(private http: HttpClient, private socialService: SocialService) {}

  ngOnInit() {
    this.login();
  }

  checkIfUserFollowsPage(userAccessToken: string) {
    const url = `https://graph.facebook.com/v12.0/${this.fbPageId}/likes?access_token=${userAccessToken}`;
    return this.http.get(url);
  }

  login() {
    this.socialService.loginWithFacebook().then(
      (accessToken) => {
        console.log('User Access Token:', accessToken);
        this.checkIfUserFollowsPage(accessToken).subscribe({
          next: (response) => {
            console.log('User likes page:', response);
          },
          error: (error) => {
            console.error('Error checking if user likes page:', error);
          },
        });
      },
      (error) => {
        console.error('Login failed:', error);
      }
    );
  }
}
