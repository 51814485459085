import { Injectable } from '@angular/core';
import Web3 from 'web3';
import { Subject } from 'rxjs';
import { getNextItem } from './utils';
import { environment } from '../environments/environment';
import { beginCell, toNano, Address } from '@ton/core';
import qs from 'qs';
import { TonService } from './ton.service';
import { HttpClient } from '@angular/common/http';
interface Nft {
  tokenId: number;
  tokenURI: any;
  owner: any;
  price: string;
}
interface CollectionItem {
  address: string;
  description: string;
  name: string;
  image: { originalUrl: string };
}

interface GetCollectionsResponse {
  cursor: string;
  items: CollectionItem[];
}

let nfts: Nft[] = [];
@Injectable({
  providedIn: 'root',
})
export class NFTService {
  subject: Subject<{
    singleMode: boolean;
    nftBody?: string;
    collectionAddress?: string;
    singleNftUrl: string;
  }> = new Subject<{
    singleMode: boolean;
    nftBody?: string;
    collectionAddress?: string;
    singleNftUrl: string;
  }>();
  collectionSubject: Subject<{
    royaltyPercent: number;
    collectionContentUrl: string;
  }> = new Subject<{
    royaltyPercent: number;
    collectionContentUrl: string;
  }>();
  ownerAddress: any;
  deploylink: string = '';
  walletID: string = '';
  query: string = `
  query NftItemConnection($ownerAddress: String!, $first: Int!,) {
    nftCollectionsByOwner(ownerAddress: $ownerAddress, first: $first) {
      cursor
      items {
        address
        description
        name
        image {
          originalUrl
        }
      }
    }
  }
  `;
  base_url: string = 'https://api.testnet.getgems.io/graphql';

  constructor(private tonService: TonService, private http: HttpClient) {}

  getWalletId() {
    this.tonService.currentWalletId.subscribe((walletId) => {
      this.walletID = walletId;
    });
  }

  public async deployNFTCollection(data: {
    royaltyPercent: number;
    collectionContentUrl: string;
  }) {
    this.collectionSubject.next(data);
  }

  public async getCollections(): Promise<GetCollectionsResponse> {
    while (this.walletID == '') {
      await new Promise((r) => setTimeout(r, 500));
      this.getWalletId();
    }
    try {
      const response = await this.http
        .post<any>(this.base_url, {
          query: this.query,
          variables: {
            ownerAddress: this.walletID,
            first: 24,
          },
        })
        .toPromise();

      return response.data.nftCollectionsByOwner;
    } catch (error) {
      console.error('Error fetching collections:', error);
      throw error;
    }
  }

  async mintNFT(data: {
    singleMode: boolean;
    nftBody?: string;
    collectionAddress?: string;
    singleNftUrl: string;
  }) {
    /////////////////////////////////////// collect data here ////////////////////////////////////////////
    while (this.walletID == '') {
      await new Promise((r) => setTimeout(r, 500));
      this.getWalletId();
    }
    this.ownerAddress = Address.parse(this.walletID);

    //take next Item
    const itemIndex = await getNextItem(data.collectionAddress);

    // no image just json
    console.log(data.singleMode);
    var commonContentUrl = data.singleMode
      ? 'https://api.helola.com/api/echo/uri/' + data.singleNftUrl
      : data.singleNftUrl;

    /////////////////////////////////////// Body generation ////////////////////////////////////////////

    const body = beginCell();
    // op == 1  = deploy single NFT
    body.storeUint(1, 32);
    // query_id let it be 0
    body.storeUint(0, 64);

    // index - take next index from file TBD
    body.storeUint(itemIndex, 64);

    body.storeCoins(toNano('0.05'));

    const nftItemContent = beginCell();
    nftItemContent.storeAddress(this.ownerAddress);

    const uriContent = beginCell();
    uriContent.storeBuffer(Buffer.from(commonContentUrl));
    nftItemContent.storeRef(uriContent.endCell());

    body.storeRef(nftItemContent.endCell());
    const readyBody = body.endCell();
    this.subject.next({
      nftBody: readyBody.toBoc().toString('base64'),
      collectionAddress: data.collectionAddress,
      singleMode: data.singleMode,
      singleNftUrl: commonContentUrl,
    });
    /////////////////////////////////////// Deploy link //////////////////////////////////////

    const collectionAddress = Address.parse(
      'EQCErOjx3Tylw2X0e5mMkUOqtbWg07KnJwTgiVbH0j7_qDpU'
    );

    let deployLink =
      'https://app.tonkeeper.com/transfer/' +
      collectionAddress.toString({
        testOnly: true,
      }) +
      '?' +
      qs.stringify({
        text: 'tonspeedrun',
        amount: toNano('0.1').toString(10),
        bin: readyBody.toBoc({ idx: false }).toString('base64'),
      });

    this.deploylink = String(deployLink);
    return this.deploylink;
  }
}
