import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NFTService } from '../nft.service';
@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
})
export class UploadFormComponent {
  name: string = '';
  description: string = '';
  selectedFile: File | null = null;
  royaltyPercent: number = 5;

  constructor(private http: HttpClient, private nftService: NFTService) {}

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      if (file.type.startsWith('image/')) {
        this.selectedFile = file;
        console.log('Image file selected:', file);
      } else {
        console.error('Please select a valid image file.');
        input.value = '';
      }
    }
  }

  async onSubmit() {
    if (this.selectedFile) {
      try {
        const formData = new FormData();
        formData.append('file', this.selectedFile);
        const uploadResponse = await this.http
          .post<any>('https://blob.helola.com/upload/', formData)
          .toPromise();
        const imageUrl = `https://blob.helola.com/download/${uploadResponse.stored_name}`;

        const payload = {
          name: this.name,
          description: this.description,
          image: imageUrl,
        };
        const jsonBlob = new Blob([JSON.stringify(payload)], {
          type: 'application/json',
        });
        const jsonFile = new File([jsonBlob], 'payload.json', {
          type: 'application/json',
        });
        const jsonFormData = new FormData();
        jsonFormData.append('file', jsonFile);
        const jsonUploadResponse = await this.http
          .post<any>('https://blob.helola.com/upload/', jsonFormData)
          .toPromise();
        const jsonFileUrl = `https://blob.helola.com/download/${jsonUploadResponse.stored_name}`;
        console.log({
          royaltyPercent: this.royaltyPercent / 100,
          collectionContentUrl: jsonFileUrl,
        });
        this.nftService.deployNFTCollection({
          royaltyPercent: this.royaltyPercent / 100,
          collectionContentUrl: jsonFileUrl,
        });
        console.log('JSON File URL:', jsonFileUrl);
      } catch (error) {
        console.error('Error during upload:', error);
      }
    }
  }
}
