import { Component, EventEmitter, Output, Input } from '@angular/core';
@Component({
  selector: 'app-sell-modal',
  templateUrl: './sell-modal.component.html',
  styleUrls: ['./sell-modal.component.css']
})
export class SellModalComponent {
  @Output() closeModal = new EventEmitter<void>();
  @Input() tokenID: any;
  price: any;
  loading = false;

  async submit() {
  }
  close() {
    this.closeModal.emit();
  }
}
