<div class="flex font-kochasan  flex-col space-y-4 mb-[20px] md:mb-[40px]">
  <h1 class="text-center font-bold text-[2.125em] pt-10">AI NFT CREATOR</h1>
  <div class="mx-[10%]">
    <div class="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0 md:space-x-3">
      <input type="text" [(ngModel)]="prompt" placeholder="Enter prompt" maxlength="4000"
        class="border outline-none border-[1px] border-[#F59ADC] bg-white shadow-md rounded-lg px-4 py-3 w-full md:w-[100%]">
      <button (click)="sendPrompt()" style="background: linear-gradient(90deg, #F59ADC, #D1A5D8);"
        class="hover:bg-blue-600 border-none shadow-md text-white font-semibold px-4 py-3 rounded-md w-full md:w-auto">Create</button>

    </div>
  </div>
  <div class="mx-[10%] w-[80%]">
    <div class="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0 md:space-x-2">
      <div class="flex space-x-2">
        <label for="model" class="self-center">Model:</label>
        <select style="border:none; border-bottom: 1px solid;" id="model" [(ngModel)]="model" class="border border-solid border-gray-300 px-4 py-2">
          <option value="dall-e-2">DALL-E 2</option>
          <option value="dall-e-3">DALL-E 3</option>
        </select>
      </div>

      <div class="flex space-x-2">
        <label for="n" class="self-center">Number of images (1-10):</label>
        <input style="border:none; border-bottom: 1px solid;" type="number" id="n" [(ngModel)]="n" min="1" max="10" [disabled]="isNDisabled()"
          class="border border-solid border-gray-300 px-4 py-2 w-24">
      </div>

      <div class="flex space-x-2">
        <label for="quality" class="self-center">Quality:</label>
        <select style="border:none; border-bottom: 1px solid;" id="quality" [(ngModel)]="quality" [disabled]="isQualityDisabled()"
          class="border border-solid border-gray-300 px-4 py-2">
          <option value="standard">Standard</option>
          <option value="hd">HD</option>
        </select>
      </div>

      <div class="flex space-x-2">
        <label for="size" class="self-center">Size:</label>
        <select style="border:none; border-bottom: 1px solid;" id="size" [(ngModel)]="size" [disabled]="isSizeDisabled()" class="border border-solid border-gray-300 px-4 py-2">
          <option value="1024x1024">1024x1024</option>
          <option value="1792x1024">1792x1024</option>
          <option value="1024x1792">1024x1792</option>
        </select>
      </div>

      <div class="flex space-x-2">
        <label for="style" class="self-center">Style:</label>
        <select style="border:none; border-bottom: 1px solid;" id="style" [(ngModel)]="style" [disabled]="isStyleDisabled()" class="border border-solid border-gray-300 px-4 py-2">
          <option value="vivid">Vivid</option>
          <option value="natural">Natural</option>
        </select>
      </div>
    </div>
  </div>

</div>
<div class="mb-[20px] md:mb-[40px] flex justify-center items-center" *ngIf="isLoading">
  <div class="text-center">
    <app-loading></app-loading>
  </div>
</div>


<!-- Image Gallery -->
<div *ngIf="imageUrls.length > 0" class="mx-[10%] w-[80%]">
  <div class="flex flex-wrap justify-evenly">
    <div *ngFor="let imageUrl of imageUrls" class="m-2" (click)="openModal(imageUrl)">
      <img [src]="imageUrl" alt="Generated Image" class="max-h-64 max-w-64 md:max-h-96 md:max-w-96 rounded-md shadow-md">
    </div>
  </div>
</div>

<app-nft-properties-modal
*ngIf="showModal"
[url]="chosenURL"
(closeModal)="closeModal()"
></app-nft-properties-modal>
