import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ImageGenerateComponent } from './image-generate/image-generate.component';
import { provideRouter } from '@angular/router';
import { LoadingComponent } from './loading/loading.component';
import { MenuComponent } from './menu/menu.component';
import { NftcardComponent } from './nftcard/nftcard.component';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { DetailnftComponent } from './detailnft/detailnft.component';
import { NftPropertiesModalComponent } from './nft-properties-modal/nft-properties-modal.component';
import { MynftComponent } from './mynft/mynft.component';
import { SellModalComponent } from './sell-modal/sell-modal.component';
import { QRCodeModule } from 'angularx-qrcode';
import { TestComponent } from './test/test.component';
import { GamesModule } from './games/games.module';
import { TeleComponent } from './tele/tele.component';
import { ArenaComponent } from './arena/arena.component';
import { CommonModule } from '@angular/common';
import { CoinsComponent } from './coins/coins.component';
import { HamstersComponent } from './hamsters/hamsters.component';
import { FriendsComponent } from './friends/friends.component';
import { MinesComponent } from './mines/mines.component';
import { SettingsComponent } from './settings/settings.component';
import { InfoComponent } from './info/info.component';
import { ReferralComponent } from './referral/referral.component';
import { InviteModalComponent } from './invite-modal/invite-modal.component';
import { UploadFormComponent } from './upload-form/upload-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomDropdownComponent } from './custom-dropdown/custom-dropdown.component';
export const routes: Routes = [
  { path: '', component:  ImageGenerateComponent},
  { path: 'marketplace', component: MarketplaceComponent},
  { path: 'detailnft/:id', component: DetailnftComponent},
  { path: 'mynft', component: MynftComponent},
  { path: 'game', component: ArenaComponent},
  { path: 'game/referral', component: ReferralComponent },
  {path : 'upload', component : UploadFormComponent},
  {path :'test', component : TestComponent},
];
@NgModule({
  declarations: [
    AppComponent,
    ImageGenerateComponent,
    LoadingComponent,
    MenuComponent,
    NftcardComponent,
    MarketplaceComponent,
    DetailnftComponent,
    NftPropertiesModalComponent,
    MynftComponent,
    SellModalComponent,
    TestComponent,
    TeleComponent,
    ArenaComponent,
    CoinsComponent,
    HamstersComponent,
    FriendsComponent,
    MinesComponent,
    SettingsComponent,
    InfoComponent,
    InviteModalComponent,
    UploadFormComponent,
    CustomDropdownComponent,
    ReferralComponent,
  ],
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    QRCodeModule,
    GamesModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  providers: [provideRouter(routes)],
  bootstrap: [AppComponent],
})
export class AppModule {}
