import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mine',
  templateUrl: './mines.component.html',
  styleUrls: ['./mines.component.css']
})
export class MinesComponent  {
  @Input() size: number = 24;
  @Input() className: string = '';

  get svgSize(): string {
    return `${this.size}px`;
  }
}
