import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html'
})
export class CustomDropdownComponent {
  @Input() collectionAddresses: { name: string, url: string }[] = [];
  @Output() selectionChange = new EventEmitter<any>();

  selectedCollection: any;
  dropdownOpen = false;

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectCollection(collection: any) {
    this.selectedCollection = collection;
    this.dropdownOpen = false;
    this.selectionChange.emit(this.selectedCollection);
  }
}
