import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-detailnft',
  templateUrl: './detailnft.component.html',
  styleUrls: ['./detailnft.component.css']
})
export class DetailnftComponent {
  id: any;
  @Input() owner: any;
  name: any;
  description: any;
  url: any;
  @Input() price: any;
  tokenID: any;
  nfts: any[] = [];
  nft: any;


  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  get_nft_info() {
    let uri = 'https://api.helola.com/api/echo/uri/?id=' + this.id;

    this.http.get<{ url: string, name: string, description: string }>(uri)
      .subscribe(response => {
        this.name = response.name;
        this.description = response.description;
        this.url = response.url;
      });
  }

  get_nft_from_list() {
    this.nft = this.nfts.find(nft => {
      const url = new URL(nft.tokenURI);
      const params = new URLSearchParams(url.search);
      const id = params.get('id');
      return id == this.id;
    });
    this.price = this.nft.price;
    this.tokenID = this.nft.tokenId;
  }

  async onSubmit() {
  }
}





