<form (ngSubmit)="onSubmit()" class="bg-white p-8 rounded-lg shadow-lg space-y-4">
  <div class="flex space-x-2 items-center">
    <label for="name" class="self-center">Name:</label>
    <input id="name" [(ngModel)]="name" name="name" type="text"
      class="border border-solid border-gray-300 px-4 py-2 w-full" style="border:none; border-bottom: 1px solid;" />
  </div>
  <div class="flex space-x-2 items-center">
    <label for="description" class="self-center">Description:</label>
    <textarea id="description" [(ngModel)]="description" name="description"
      class="border border-solid border-gray-300 px-4 py-2 w-full"
      style="border:none; border-bottom: 1px solid;"></textarea>
  </div>
  <div class="flex space-x-2 items-center">
    <label for="royaltyPercent" class="self-center">Royalty percent:</label>
    <input min='0' [(ngModel)]="royaltyPercent" name="royaltyPercent" max="100" id="royaltyPercent" type="number"
      class="border border-solid border-gray-300 px-4 py-2 w-[20%]" />
  </div>
  <div class="flex space-x-2 items-center">
    <label for="file" class="self-center">Image:</label>
    <input id="file" type="file" (change)="onFileSelected($event)"
      class="border border-solid border-gray-300 px-4 py-2 w-full" accept="image/*" />
  </div>
  <button type="submit"
    class="bg-[#F59ADC] hover:bg-[#D1A5D8] text-white font-semibold w-full px-2 py-1 md:px-4 md:py-3 rounded-md">Submit</button>
</form>
