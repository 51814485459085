<div id="points-container" style="position: absolute; z-index: 1000;"></div>
<div class="fixed inset-0 bg-black">
</div> <!-- Fixed black background that covers the entire viewport -->
<div class="relative prevent-select flex justify-center touch-none disable-swipe touch-manipulation">

  <div id="#container" class="w-full bg-black text-white h-screen font-bold flex flex-col max-w-xl relative z-10">

    <div class="px-4">
    </div>
    <div class="flex items-center space-x-2 pt-4">
      <div class="p-1 rounded-lg bg-[#1d2025]">
        <app-hamster [size]="24" class="text-[#d4d4d4]"></app-hamster>
        <div>
          <p class="text-sm">Hi, {{ telegramUserID }}</p>
          <p class="text-sm">{{ energy }}/{{ energyCap }}</p>

        </div>
      </div>
      <div class="flex items-center justify-between space-x-4 mt-1">
        <div class="flex items-center w-1/3">
          <div class="w-full">
            <div class="flex justify-between">
              <p class="text-sm">{{ levelNames[levelIndex] }}</p>
              <p class="text-sm">
                {{ levelIndex + 1 }} <span class="text-[#95908a]">/ {{ levelNames.length }}</span>
              </p>
            </div>
            <div class="flex items-center mt-1 border-2 border-[#43433b] rounded-full">
              <div class="w-full h-2 bg-[#43433b]/[0.6] rounded-full">
                <div class="progress-gradient h-2 rounded-full" [style.width.%]="calculateProgress()"></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex items-center w-2/3 border-2 border-[#43433b] rounded-full px-4 py-[2px] bg-[#43433b]/[0.6] max-w-64">
          <img draggable="false" src="/assets/sample/binance-logo.png" alt="Exchange" class="w-8 h-8" />
          <div class="h-[32px] w-[2px] bg-[#43433b] mx-2"></div>
          <div class="flex-1 text-center">
            <p class="text-xs text-[#85827d] font-medium">Profit per hour</p>
            <div class="flex items-center justify-center space-x-1">
              <img draggable="false" src="/assets/sample/dollar-coin.png" alt="Dollar Coin" class="w-[18px] h-[18px]" />
              <p class="text-sm">{{ formatProfitPerHour(profitPerHour) }}</p>
              <app-info [size]="20" class="text-[#43433b]"></app-info>
            </div>
          </div>
          <div class="h-[32px] w-[2px] bg-[#43433b] mx-2"></div>
          <app-settings class="text-white"></app-settings>
        </div>
      </div>
    </div>

    <div class="flex-grow mt-4 bg-[#f3ba2f] rounded-t-[48px] relative top-glow z-0">
      <div class="absolute top-[2px] left-0 right-0 bottom-0 bg-[#1d2025] rounded-t-[46px]">
        <div class="px-4 mt-6 flex flex-wrap justify-between gap-2">
          <div class="bg-[#272a2f] rounded-lg px-4 py-2 flex-1 relative" (click)="claimDailyReward()">
            <div class="dot"></div>
            <img src="/assets/sample/daily-reward.png" draggable="false" alt="Daily Reward" class="mx-auto w-12 h-12" />
            <p class="text-[10px] text-center text-white mt-1">Daily reward</p>
            <p *ngIf="dailyRewardTimeLeft > 0" class="text-[10px] font-medium text-center text-gray-400 mt-2">{{
              dailyRewardTimeLeft
              | date: 'hh:mm:ss' }}</p>
            <p *ngIf="dailyRewardTimeLeft <= 0" class="text-[10px] font-medium text-center text-gray-400 mt-2">Claimable
            </p>
          </div>
          <div class="bg-[#272a2f] rounded-lg px-4 py-2 flex-1  relative">
            <div class="dot"></div>
            <img draggable="false" src="/assets/sample/daily-cipher.png" alt="Daily Cipher" class="mx-auto w-12 h-12" />
            <p class="text-[10px] text-center text-white mt-1">Daily cipher</p>
            <p *ngIf="dailyCipherTimeLeft > 0" class="text-[10px] font-medium text-center text-gray-400 mt-2">{{
              dailyCipherTimeLeft | date: 'mm:ss' }}</p>
            <p *ngIf="dailyCipherTimeLeft <= 0" class="text-[10px] font-medium text-center text-gray-400 mt-2">Claimable
            </p>
          </div>
          <div class="bg-[#272a2f] rounded-lg px-4 py-2 flex-1 relative">
            <div class="dot"></div>
            <img draggable="false" src="/assets/sample/daily-combo.png" alt="Daily Combo" class="mx-auto w-12 h-12" />
            <p class="text-[10px] text-center text-white mt-1">Daily combo</p>
            <p *ngIf="dailyComboTimeLeft > 0" class="text-[10px] font-medium text-center text-gray-400 mt-2">{{
              dailyComboTimeLeft | date: 'mm:ss' }}</p>
            <p *ngIf="dailyComboTimeLeft <= 0" class="text-[10px] font-medium text-center text-gray-400 mt-2">Claimable
            </p>
          </div>
        </div>

        <div class="px-4 mt-4 flex justify-center">
          <div class="px-4 py-2 flex items-center space-x-2">
            <img draggable="false" src="/assets/sample/dollar-coin.png" alt="Dollar Coin" class="w-10 h-10" />
            <p class="text-4xl text-white">{{ points | number }}</p>
          </div>
        </div>
        <div class="px-4 mt-4 flex justify-center">
          <div class="w-80 h-80 p-4 rounded-full circle-outer" (click)="handleCardClick($event)">
            <div class="w-full h-full rounded-full circle-inner">
              <img draggable="false" src="/assets/sample/main-character.png" alt="Main Character"
                class="w-full h-full" />
            </div>
          </div>
        </div>

        <!-- Add the points container here -->
      </div>
    </div>
  </div>

  <!-- Bottom fixed div -->
  <div
    class="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full max-w-xl bg-[#272a2f] flex justify-around items-center z-50 rounded-3xl text-xs px-4">
    <div class="text-center text-[#85827d] bg-[#1c1f24] m-1 p-2 rounded-2xl">
      <img src="/assets/sample/binance-logo.png" draggable="false" alt="Exchange" class="w-8 h-8 mx-auto" />
      <p class="mt-1">Exchange</p>
    </div>
    <div class="text-center text-[#85827d]">
      <div style="margin-left:50%;transform: translateX(-50%);"><app-mine class="w-8 h-8 mx-auto"></app-mine></div>
      <p class="mt-1">Mine</p>
    </div>
    <div class="text-center text-[#85827d]" (click)="openInviteModal()">
      <div style="margin-left:50%;transform: translateX(-50%);"><app-friends class="w-8 h-8 mx-auto"></app-friends>
      </div>
      <p class="mt-1">Friends</p>
    </div>
    <div class="text-center text-[#85827d]" (click)="openBoostModal()">
      <div style="margin-left:50%;transform: translateX(-50%);"><app-coins class="w-8 h-8 mx-auto"></app-coins></div>
      <p class="mt-1">Upgrade</p>
    </div>
    <div class="text-center text-[#85827d]">
      <div style="margin-left:50%;transform: translateX(-50%);"><img draggable="false"
          src="/assets/sample/hamster-coin.png" draggable="false" alt="Airdrop" class="w-8 h-8 mx-auto" /></div>
      <p class="mt-1">Airdrop</p>
    </div>
  </div>

  <div *ngFor="let click of clicks" class="absolute text-5xl font-bold opacity-0 text-white pointer-events-none"
    [ngStyle]="{ top: (click.y - 42) + 'px', left: (click.x - 28) + 'px', animation: 'float 1s ease-out' }"
    (animationend)="handleAnimationEnd(click.id)">
    {{ pointsToAdd }}
  </div>
</div>
<app-invite-modal *ngIf="isModalOpen" [current_energy]="energyCap" [current_boost]="boostValue"
  [telegramUserID]="telegramUserID" [upgrade]="upgradeMode" (closeModal)="closeModal()"></app-invite-modal>

<!-- <app-referral></app-referral> -->
