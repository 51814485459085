import { Injectable } from '@angular/core';

declare var FB: any;

@Injectable({
  providedIn: 'root',
})
export class SocialService {
  private fbInitialized = false;

  constructor() {
    this.loadFacebookSDK();
  }

  private loadFacebookSDK() {
    ((d, s, id) => {
      let js: HTMLScriptElement,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;

      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';

      // Error handling for loading the Facebook SDK
      js.onerror = function () {
        console.error('Failed to load the Facebook SDK.');
      };

      if (fjs && fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs);
      } else {
        d.head.appendChild(js); // Fallback: append to head if fjs or its parent is null
      }
    })(document, 'script', 'facebook-jssdk');

    (window as any).fbAsyncInit = () => {
      console.log('Facebook SDK initialized.');
      FB.init({
        appId: '429658023449518', // Replace with your actual App ID
        xfbml: true,
        version: 'v20.0', // Ensure you're using a valid version
      });
      this.fbInitialized = true;
    };
  }

  private ensureFbInitialized(): Promise<void> {
    return new Promise((resolve) => {
      if (this.fbInitialized) {
        console.log('Facebook SDK already initialized.');
        resolve();
      } else {
        console.log('Waiting for Facebook SDK to initialize...');
        (window as any).fbAsyncInit = () => {
          FB.init({
            appId: '429658023449518', // Replace with your actual App ID
            xfbml: true,
            version: 'v20.0', // Ensure you're using a valid version
          });
          this.fbInitialized = true;
          console.log('Facebook SDK initialized during ensure.');
          resolve();
        };
      }
    });
  }

  loginWithFacebook(): Promise<string> {
    console.log('Attempting to login with Facebook.');
    return this.ensureFbInitialized().then(() => {
      if (!FB) {
        console.error('Facebook SDK not loaded properly');
        return Promise.reject('Facebook SDK not loaded properly');
      }

      return new Promise((resolve, reject) => {
        console.log('Calling FB.login...');
        FB.login(
          (response: any) => {
            if (response.authResponse) {
              console.log('User logged in and granted some permissions.');
              console.log('Access Token:', response.authResponse.accessToken);
              resolve(response.authResponse.accessToken);
            } else {
              console.warn('User cancelled login or did not fully authorize.');
              reject('User cancelled login or did not fully authorize.');
            }
          },
          { scope: 'public_profile,user_likes' }
        );
      });
    });
  }
}
