import { Component } from '@angular/core';
import { NFTService } from '../nft.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
@Component({
  selector: 'app-mynft',
  templateUrl: './mynft.component.html',
  styleUrls: ['./mynft.component.css']
})
export class MynftComponent {
  nfts: any[] = [];
  account: any;
  tokenID: any;
  price: any;
  showModal = false;

  filterNFTs() {
    this.nfts = this.nfts.filter(nft => nft.owner === this.account);
  }

  openModal(tokenID: any) {
    this.tokenID = tokenID;
    this.showModal = true;
  }

  closeModal() {
    this.tokenID = undefined;
    this.showModal = false;
  }
}
