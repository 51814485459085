<div class="relative">
  <button (click)="toggleDropdown()" class="border border-gray-300 px-4 py-2 w-full text-left flex items-center">
    <img *ngIf="selectedCollection?.url" [src]="selectedCollection.url" class="h-6 w-6 mr-2">
    {{ selectedCollection?.name || 'Select a collection' }}
    <svg class="ml-auto h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
    </svg>
  </button>

  <ul *ngIf="dropdownOpen"
    class="absolute z-10 bg-white border border-gray-300 w-full mt-1 max-h-60 overflow-auto shadow-lg">
    <li *ngFor="let collection of collectionAddresses" (click)="selectCollection(collection)"
      class="px-4 py-2 flex items-center cursor-pointer hover:bg-gray-100">
      <img [src]="collection.url" class="h-6 w-6 mr-2">
      {{ collection.name }}
    </li>
  </ul>
</div>
