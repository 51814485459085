import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
declare const Telegram: any;
@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.css'],
})
export class ReferralComponent implements OnInit {
  telegramUserID: number | null = 1;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {}

  private fetchTelegramUserID() {
    if (Telegram && Telegram.WebApp) {
      this.telegramUserID = Telegram.WebApp.initDataUnsafe?.user?.id || null;

      if (this.telegramUserID) {
        console.log(`Telegram User ID: ${this.telegramUserID}`);
        // Call backend service to update or fetch user data
      } else {
        console.error('Telegram user ID not found.');
      }
    }
  }
  ngOnInit(): void {
    const initData = Telegram.WebApp.initDataUnsafe;
    alert(initData);
    const startParam = initData.start_param || initData.query_id;
    alert(startParam);
    const params = new URLSearchParams(startParam);
    const referrerTelegramId = params.get('referrer_id');
    this.fetchTelegramUserID();
    const userTelegramId = this.telegramUserID;
    alert('Referrer ID: ' + referrerTelegramId);
    console.log('Referrer ID:', referrerTelegramId);
    console.log('User ID:', userTelegramId);
    if (referrerTelegramId && userTelegramId) {
      this.http
        .post('https://api.helola.com/api/game/users/register/', {
          telegram_id: userTelegramId,
          referrer_id: referrerTelegramId,
        })
        .subscribe({
          next: () => {
            this.router.navigate(['/game']);
          },
          error: (error) => {
            console.error('Error processing referral', error);
            this.router.navigate(['/game']);
          },
        });
    } else {
      // No referrer ID or user telegram ID, just navigate to the game component
      this.router.navigate(['/game']);
    }
  }
}
