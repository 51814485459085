import { Component } from '@angular/core';

@Component({
  selector: 'app-tele',
  templateUrl: './tele.component.html',
  styleUrls: ['./tele.component.css']
})
export class TeleComponent {

}
