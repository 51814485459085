import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ArenaService } from '../arena.service';
@Component({
  selector: 'app-invite-modal',
  templateUrl: './invite-modal.component.html',
  styleUrls: ['./invite-modal.component.css'],
})
export class InviteModalComponent {
  @Input() telegramUserID: number | null = null;
  @Input() upgrade: boolean = true;
  @Input() current_energy: number = 1000;
  @Input() current_boost: number = 1.0;
  @Output() closeModal = new EventEmitter<void>();
  inviteLink: string = '';
  boostUpgradeCost: number = 0;
  energyUpgradeCost: number = 0;

  constructor(private gameService: ArenaService) {}

  ngOnInit() {
    this.inviteLink = `https://t.me/isuccess_bot/ratgame?startApp=referrer_id=${this.telegramUserID}`;
    this.boostUpgradeCost = 1000 * 2 ** this.current_boost;
    this.energyUpgradeCost = 1000 * 2 ** (this.current_energy / 500 - 1);
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.inviteLink).then(() => {
      this.close();
    });
  }

  openTelegram() {
    const startParam = `ratgame?startApp=referrer_id=${this.telegramUserID}`;
    window.open(
      `tg://resolve?domain=isuccess_bot&start=${encodeURIComponent(
        startParam
      )}`,
      '_blank'
    );
  }
  upgradeBoost() {
    this.gameService.upgradeUserLevel(this.telegramUserID, 'boost').subscribe({
      next: () => {
        this.close();
      },
      error: (error) => {
        console.error('Error upgrading boost', error);
      },
    });
  }
  upgradeEnergy() {
    this.gameService.upgradeUserLevel(this.telegramUserID, 'energy').subscribe({
      next: () => {
        this.close();
      },
      error: (error) => {
        console.error('Error upgrading energy', error);
      },
    });
  }
  close() {
    this.closeModal.emit();
  }
}
