<div class="border border-[#AEAAAA]-500 rounded-[10px] max-w-[300px] w-full">
  <div class="p-2 w-full">
    <img src="{{ image_source }}" class="m-auto">
    <p class="text-center pt-[0.5em] overflow-hidden text-ellipsis whitespace-nowrap text-black-500 pb-[0.5em]">{{ name }}</p>
    <hr>
    <div class="grid w-[100%] md:grid-cols-2 gap-2 grid-cols-1">
      <div class="px-3 justify-center md:justify-start md:text-[1em] mt-2 text-[0.8em] flex items-center overflow-hidden text-ellipsis whitespace-nowrap max-w-[150px] w-[100%]">
        <p *ngIf="price != '0.'">Price: {{ price }} TON</p>
        <p *ngIf="price == '0.'">Not for sales</p>
      </div>
      <div class="flex justify-center items-center pt-[0.5em]">
        <a><button class="bg-[#F59ADC] hover:bg-[#D1A5D8] text-white font-semibold w-full px-2 py-1 md:px-4 md:py-3 rounded-md">{{ transaction_type }}</button></a>
      </div>
    </div>
  </div>
</div>
