import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-nftcard',
  templateUrl: './nftcard.component.html',
  styleUrls: ['./nftcard.component.css']
})
export class NftcardComponent {
  @Input() uri: any;
  @Input() price: any;
  image_source: any;
  @Input() transaction_type: string = 'Buy';
  name: any;
  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.get_nft_info();
  }

  get_nft_info() {
    this.http.get<{ url: string, name: string  }>(this.uri)
      .subscribe(response => {
        this.image_source = response.url;
        this.name = response.name;
      });
  }
}
