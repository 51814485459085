<!-- app-nft-properties-modal.component.html -->
<div class="fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-50">
  <div class="bg-white p-8 rounded-lg md:w-[550px] md:h-[450px] h-[350px] shadow-lg relative">
    <!-- Close button -->
    <button class="absolute top-0 right-0 m-2 focus:outline-none" (click)="close()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>

    <h1 class="text-[1.5em] font-bold">List NFT</h1>
    <div class="flex space-x-2">
      <label for="style" class="self-center">Price:</label>
      <input style="border:none; border-bottom: 1px solid;" type="number" id="price" [(ngModel)]="price"
        class="border border-solid border-gray-300 px-4 py-2 w-full">
    </div>

    <button (click)="submit()" [disabled]=""
      class="my-10 bg-[#F59ADC] hover:bg-[#D1A5D8] text-white font-semibold w-full px-2 py-1 md:px-4 md:py-3 rounded-md">Mint
      NFT</button>
  </div>
</div>
