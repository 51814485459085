import { Component, EventEmitter, Output, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NFTService } from '../nft.service';

@Component({
  selector: 'app-nft-properties-modal',
  templateUrl: './nft-properties-modal.component.html',
  styleUrls: ['./nft-properties-modal.component.css'],
})
export class NftPropertiesModalComponent {
  @Output() closeModal = new EventEmitter<void>();
  @Input() url = '';
  name: string = '';
  price: number = 0;
  description: string = '';
  loading = false;
  deploylink: string = '';
  confirm: boolean = false;
  mintSingle: boolean = true;
  collectionAddr: string = '';
  selectedCollection: { name: string; address: string; url: string } | null =
    null;
  collectionAddresses: { name: string; address: string; url: string }[] = [];
  attributes: Array<{ trait_type: string; value: string }> = [];
  showUploadForm: boolean = false; // Add this flag

  constructor(private http: HttpClient, private NFTService: NFTService) {
    this.getCollections();
  }

  async mint(id: number) {
    this.confirm = true;
    const data: {
      singleMode: boolean;
      nftBody?: string;
      collectionAddress?: string;
      singleNftUrl: string;
    } = {
      singleMode: this.mintSingle,
      nftBody: '',
      collectionAddress: this.selectedCollection?.address,
      singleNftUrl: '?id=' + String(id),
    };
    console.log(data.singleMode);
    this.deploylink = await this.NFTService.mintNFT(data);
  }

  async getCollections() {
    const data = await this.NFTService.getCollections();
    data.items.forEach(async (collection) => {
      this.collectionAddresses.push({
        name: collection.name,
        address: collection.address,
        url: collection.image.originalUrl,
      });
    });
    console.log(this.collectionAddresses);
  }

  toggleMintSingle() {
    this.mintSingle = !this.mintSingle;
    console.log('status: ', this.mintSingle);
  }

  addAttribute() {
    const hasEmpty = this.attributes.some(
      (attr) => attr.trait_type === '' || attr.value === ''
    );
    if (hasEmpty) {
      alert(
        'Please fill in all existing attribute fields before adding a new one.'
      );
      return;
    }
    this.attributes.push({ trait_type: '', value: '' });
  }

  removeAttribute(index: number) {
    this.attributes.splice(index, 1);
  }

  copyToClipboard(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((err) => {
        console.error('Could not copy text: ', err);
      });
  }

  submit() {
    this.loading = true;
    const url = 'https://api.helola.com/api/echo/uri/';
    this.http
      .post<{ id: number }>(url, {
        name: this.name,
        image: this.url,
        external_url: this.url,
        description: this.description,
        attributes: this.attributes,
      })
      .subscribe((response) => {
        this.mint(response.id);
      });
    this.loading = false;
  }

  close() {
    this.closeModal.emit();
  }

  toggleUploadForm() {
    this.showUploadForm = !this.showUploadForm;
  }
}
