import { Component, Input  } from '@angular/core';

@Component({
  selector: 'app-hamster',
  templateUrl: './hamsters.component.html',
  styleUrls: ['./hamsters.component.css']
})
export class HamstersComponent {
  @Input() size: number = 24;
  @Input() className: string = '';

  get svgSize(): string {
    return `${this.size}px`;
  }
}
