<div class="mx-[5%]">
  <h2 class="font-kodchasan mt-[0.83em] mb-[0.83em] weight-600 text-[30px]">{{ name }}</h2>
  <div class="grid md:grid-cols-2 grid-cols-1 gap-10">
    <div>
      <img src="{{ url }}" class="w-full">
    </div>
    <div class="border border-[#D1A5D8]  py-10">
      <div class="grid grid-cols-2  px-5">
        <div>
          <p class="font-semibold text-gradient">Type</p>
          <p class="text-[#AEAAAA] text-[1em]">Single item</p>
        </div>
        <div>
          <p class="font-semibold text-gradient">Owner:</p>
          <p class='text-[#AEAAAA] text-[1em] pr-2 overflow-hidden text-ellipsis whitespace-nowrap'>
            {{ owner }}</p>
        </div>
      </div>
      <div class="px-5 py-10">
        <h1 class="text-[#AEAAAA] text-[1em] text-gradient">DESCRIPTION</h1>
        <p>{{description}}</p>
        <button (click)="onSubmit()"
          class="my-10 bg-[#F59ADC] hover:bg-[#D1A5D8] text-white font-semibold w-full px-2 py-1 md:px-4 md:py-3 rounded-md">Buy</button>
        <div class="grid grid-cols-3">
          <!-- price volumn and type -->
          <div>
            <p class="font-semibold text-gradient">Price</p>
            <p class="text-[#AEAAAA] text-[1.3em]">{{ price }} ETH</p>
          </div>
          <div>
            <p class="font-semibold text-gradient">Volumn</p>
            <p class="text-[#AEAAAA] text-[1.3em]">1</p>
          </div>
          <div>
            <p class="font-semibold text-gradient">Chain</p>
            <p class="text-[#AEAAAA] text-[1.3em]">OPBNB CHAIN</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mx-[5%]">
  <h2 class="font-kodchasan mt-[0.83em] mb-[0.83em] weight-600 text-[30px]">Similar NFTs</h2>
  <div class="item-container grid grid-cols-2 md:grid-cols-4 gap-5">
    <app-nftcard></app-nftcard>
    <app-nftcard></app-nftcard>
    <app-nftcard></app-nftcard>
    <app-nftcard></app-nftcard>
    <app-nftcard></app-nftcard>
    <app-nftcard></app-nftcard>
    <app-nftcard></app-nftcard>
  </div>
</div>
