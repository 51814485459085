<!-- Modal Overlay -->
<div class="fixed z-[404004] inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center" (click)="close()">
  <!-- Modal Content -->
  <div *ngIf="!upgrade" class="bg-white p-6 rounded-lg shadow-lg w-96" (click)="$event.stopPropagation()">
    <h2 class="text-xl font-semibold text-center mb-4">Invite Friends</h2>
    <p class="text-center mb-4">Share this link with your friends:</p>
    <input type="text" [value]="inviteLink" readonly class="w-full p-2 border border-gray-300 rounded mb-4" />
    <div class="flex justify-center gap-4">
      <button (click)="copyToClipboard()" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Copy to
        Clipboard</button>
      <button (click)="openTelegram()" class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">Open in
        Telegram</button>
    </div>
    <button (click)="close()"
      class="mt-4 block mx-auto bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400">Close</button>
  </div>
  <div *ngIf="upgrade" class="bg-white p-6 rounded-lg shadow-lg w-96" (click)="$event.stopPropagation()">
    <h2 class="text-xl font-semibold text-center mb-4">Upgrade your game</h2>
    <p class="text-center mb-4">Choose your upgrade:</p>
    <div class="flex justify-center gap-4">
      <button (click)="upgradeBoost()" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Boost
        value: {{ boostUpgradeCost }}</button>
      <button (click)="upgradeEnergy()"
        class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">Energy: {{ energyUpgradeCost }}</button>
    </div>
    <button (click)="close()"
      class="mt-4 block mx-auto bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400">Close</button>
  </div>
</div>
