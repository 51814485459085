<div class="fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-50">
  <div class="bg-white p-8 rounded-lg shadow-lg relative">
    <ng-container *ngIf="!showUploadForm">
      <!-- Close button -->
      <button class="absolute top-2 right-2 p-2 bg-gray-200 rounded-full hover:bg-gray-300 focus:outline-none"
        (click)="close()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>

      <h1 class="text-2xl font-bold mb-4">Mint NFT</h1>

      <div *ngIf="!confirm">
        <div class="flex flex-col space-y-4">
          <div class="flex space-x-2 items-center">
            <label for="name" class="w-1/3">Name:</label>
            <input type="text" id="name" [(ngModel)]="name" class="border border-gray-300 px-4 py-2 w-2/3 rounded-md">
          </div>

          <div class="flex space-x-2 items-center">
            <label for="description" class="w-1/3">Description:</label>
            <input type="text" id="description" [(ngModel)]="description"
              class="border border-gray-300 px-4 py-2 w-2/3 rounded-md">
          </div>

          <div class="flex items-center space-x-2">
            <label for="collectionAddr" class="w-1/3">Collection:</label>
            <input type="checkbox" (click)="toggleMintSingle()">
            <app-custom-dropdown *ngIf="!mintSingle" [collectionAddresses]="collectionAddresses"
              (selectionChange)="selectedCollection = $event"></app-custom-dropdown>
          </div>

          <div *ngIf="selectedCollection" class="flex items-center space-x-2">
            <label class="truncate w-1/2">Collection Address:</label>
            <span class="truncate w-1/2">{{ selectedCollection.address | slice:0:4 }}...{{ selectedCollection.address |
              slice:-3 }}</span>
            <button (click)="copyToClipboard(selectedCollection.address)" class="ml-2">
              <img src="assets/icons/copy-icon.svg" alt="Copy to Clipboard" class="w-4 h-4">
            </button>
          </div>

          <div *ngFor="let attribute of attributes; let i = index" class="flex space-x-2 items-center">
            <label for="trait_type_{{i}}" class="w-1/3">Trait Type:</label>
            <input type="text" id="trait_type_{{i}}" [(ngModel)]="attribute.trait_type"
              class="border border-gray-300 px-4 py-2 w-2/3 rounded-md">
            <label for="value_{{i}}" class="w-1/3">Value:</label>
            <input type="text" id="value_{{i}}" [(ngModel)]="attribute.value"
              class="border border-gray-300 px-4 py-2 w-2/3 rounded-md">
            <button (click)="removeAttribute(i)"
              class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">Remove</button>
          </div>

          <button (click)="addAttribute()"
            class="bg-[#F59ADC] hover:bg-[#D1A5D8] text-white font-semibold w-full px-4 py-2 rounded-md">Add
            Attribute</button>

          <button *ngIf="!confirm" (click)="submit()"
            class="bg-[#F59ADC] hover:bg-[#D1A5D8] text-white font-semibold w-full px-4 py-2 rounded-md">Mint
            NFT</button>
        </div>
      </div>

      <div *ngIf="confirm" class="text-center">
        <app-loading></app-loading>
        <p class="mt-4">Please confirm the transaction in your wallet apps!</p>
      </div>
    </ng-container>

    <button *ngIf="!showUploadForm" (click)="toggleUploadForm()"
      class="bg-[#F59ADC] hover:bg-[#D1A5D8] text-white font-semibold w-full px-4 py-2 rounded-md mt-4">Create
      Collection</button>

    <app-upload-form *ngIf="showUploadForm"></app-upload-form>
  </div>
</div>
