import { KeyPair, mnemonicToPrivateKey } from "@ton/crypto";
import {
    beginCell,
    Cell,
    OpenedContract,
} from "@ton/core";
import { WalletContractV4,  } from "@ton/ton";
import { TonClient } from "@ton/ton";
export type OpenedWallet = {
    contract: OpenedContract<WalletContractV4>;
    keyPair: KeyPair;
};

export async function openWallet(mnemonic: string[], testnet: boolean) {
    const keyPair = await mnemonicToPrivateKey(mnemonic);
    const toncenterBaseEndpoint: string = testnet
        ? "https://testnet.toncenter.com"
        : "https://toncenter.com";

    const client = new TonClient({
        endpoint: `${toncenterBaseEndpoint}/api/v2/jsonRPC`,
        apiKey: "874c0d7bf95fdb25a41914e532bd9a30286e8c4bf244c787396a86dce1f5b362",
    });
    const wallet = WalletContractV4.create({
        workchain: 0,
        publicKey: keyPair.publicKey,
    });

    const contract = client.open(wallet);
    return { contract, keyPair };
}

function bufferToChunks(buff: Buffer, chunkSize: number) {
    const chunks: Buffer[] = [];
    while (buff.byteLength > 0) {
        chunks.push(buff.subarray(0, chunkSize));
        buff = buff.subarray(chunkSize);
    }
    return chunks;
}

function makeSnakeCell(data: Buffer): Cell {
    const chunks = bufferToChunks(data, 127);

    if (chunks.length === 0) {
        return beginCell().endCell();
    }

    if (chunks.length === 1) {
        return beginCell().storeBuffer(chunks[0]).endCell();
    }

    let curCell = beginCell();

    for (let i = chunks.length - 1; i >= 0; i--) {
        const chunk = chunks[i];

        curCell.storeBuffer(chunk);

        if (i - 1 >= 0) {
            const nextCell = beginCell();
            nextCell.storeRef(curCell);
            curCell = nextCell;
        }
    }

    return curCell.endCell();
}

export function encodeOffChainContent(content: string) {
    let data = Buffer.from(content);
    const offChainPrefix = Buffer.from([0x01]);
    data = Buffer.concat([offChainPrefix, data]);
    return makeSnakeCell(data);
}
